import { helpers } from 'helpers/helpers'
import {
  DELIVERY_DATE_EXCEPTIONS_CLEAR_DATA,
  DELIVERY_DATE_EXCEPTIONS_CLEAR_NEW,
  DELIVERY_DATE_EXCEPTIONS_FETCH_FAILED,
  DELIVERY_DATE_EXCEPTIONS_FETCH_REQUESTED,
  DELIVERY_DATE_EXCEPTIONS_FETCH_SUCCEEDED,
  DELIVERY_DATE_EXCEPTIONS_SELECT_NEW,
  DELIVERY_DATE_EXCEPTIONS_CREATE_ACCEPTED,
  DELIVERY_DATE_EXCEPTIONS_CREATE_FAILED,
  DELIVERY_DATE_EXCEPTIONS_CREATE_BY_CSV_REQUESTED,
  DELIVERY_DATE_EXCEPTIONS_CREATE_BY_CSV_UPLOADED,
} from 'actions/types'

const initialState = {
  isLoading: false,
  data: null,
  newExceptions: {},
}

const deliveryDateExceptions = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_DATE_EXCEPTIONS_FETCH_REQUESTED:
      return {
        isLoading: true,
        data: null,
        newExceptions: {},
      }
    case DELIVERY_DATE_EXCEPTIONS_FETCH_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      }
    case DELIVERY_DATE_EXCEPTIONS_FETCH_FAILED:
    case DELIVERY_DATE_EXCEPTIONS_CLEAR_DATA:
      return initialState
    case DELIVERY_DATE_EXCEPTIONS_SELECT_NEW:
      return {
        ...state,
        newExceptions: {
          ...state.newExceptions,
          ...action.data,
        },
      }
    case DELIVERY_DATE_EXCEPTIONS_CLEAR_NEW: {
      const updatedExceptions = helpers.removeFromHash(action.key, state.newExceptions)

      return {
        ...state,
        newExceptions: updatedExceptions,
      }
    }
    case DELIVERY_DATE_EXCEPTIONS_CREATE_ACCEPTED: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case DELIVERY_DATE_EXCEPTIONS_CREATE_FAILED: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case DELIVERY_DATE_EXCEPTIONS_CREATE_BY_CSV_REQUESTED: {
      return {
        isLoading: true,
        parsedCsvContent: [],
        showMessage: false,
        validationErrors: {},
      }
    }
    case DELIVERY_DATE_EXCEPTIONS_CREATE_BY_CSV_UPLOADED: {
      return {
        ...state,
        parsedCsvContent: action.parsedCsvContent,
      }
    }
    default:
      return state
  }
}

export default deliveryDateExceptions
