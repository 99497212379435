import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'

import { getContinentByCountry } from 'helpers/countryHelper'
import { pickCSVTemplate } from 'config/csvTemplates'
import FlashMessage from '../flashMessage/flashMessage'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'
import FirstDeliveryWeekPicker from './firstDeliveryWeekPicker/firstDeliveryWeekPicker'
import { csvContainsLowCutoffDays } from 'helpers/csvLowCutoffDaysChecker'

import './deliveryOptionsUpdate.css'
import { updateDeliveryOptionsCsvUpload } from 'actions/deliveryOptions'

import { showModal } from 'actions/modal'
import { DELIVERY_OPTIONS_UPDATE_PREVIEW_MODAL } from 'components/modal/modalTypes'

import { DeliveryOptionErrorFormatter } from 'helpers/deliveryOptionErrorFormatter'

const INFO_MESSAGE_TEXT =
  'Updating delivery options is a replacement of an existing option with a new one. </br>' +
  'If the desired option does not exist yet, please create it first and then proceed with the ' +
  'replacement. <br/> Updating will move all orders (regular and special) and user plans to ' +
  'the new delivery option. Old delivery options will no longer be available.'

export class DeliveryOptionsUpdate extends React.Component {
  errorFormatter = new DeliveryOptionErrorFormatter()

  constructor(props) {
    super(props)

    this.state = {
      selectedFirstDeliveryWeek: null,
      showMessage: false,
      showCutoffDaysWarning: false,
    }
  }

  componentDidMount() {
    this.setState({
      showMessage: false,
      showCutoffDaysWarning: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.csvContent !== prevProps.csvContent) {
      this.setState({ selectedFirstDeliveryWeek: null })
    }

    if (this.props.responseBody !== prevProps.responseBody) {
      this.setState({ showMessage: !!this.props.responseBody })
    }
  }

  hideMessage = () => {
    this.setState({ showMessage: false })
  }

  setFirstDeliveryWeek = selectedFirstDeliveryWeek => {
    this.setState({ selectedFirstDeliveryWeek })
  }

  onFileUpload = csvData => {
    this.setState({ showCutoffDaysWarning: csvContainsLowCutoffDays(csvData, 'update') })

    this.props.dispatch(updateDeliveryOptionsCsvUpload(csvData))
  }

  openPreviewModal = () => {
    this.props.dispatch(
      showModal(DELIVERY_OPTIONS_UPDATE_PREVIEW_MODAL, {
        firstDeliveryWeek: this.state.selectedFirstDeliveryWeek,
        showCutoffDaysWarning: this.state.showCutoffDaysWarning,
      }),
    )
  }

  isBtnDisabled() {
    const { country, brand, csvContent } = this.props
    return !country || !brand || !csvContent || !this.state.selectedFirstDeliveryWeek
  }

  getTemplateByType = type => {
    const { country } = this.props
    if (!country) return null

    const continent = getContinentByCountry(country)

    return pickCSVTemplate('update-delivery-options', continent, type)
  }

  renderTemplateLinks = () => {
    const { country } = this.props
    if (country) {
      return (
        <div className='delivery-options-update__template-links'>
          <ZiplogLink
            href={this.getTemplateByType('region')}
            className='delivery-options-update__ziplog-link'
            text='CSV Template by Region'
            openInNewTab
          />
          <ZiplogLink
            href={this.getTemplateByType('zip')}
            className='delivery-options-update__ziplog-link'
            text='CSV Template by Zipcode'
            openInNewTab
          />
        </div>
      )
    } else {
      return (
        <Alert severity='info' sx={{ width: 'max-content', marginTop: '10px' }}>
          Please select a country to view templates
        </Alert>
      )
    }
  }

  render() {
    if (this.props.isLoading) {
      return <div className='delivery-options-update__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div className='delivery-options-update'>
          <FlashMessage
            showMessage={this.state.showMessage}
            message={(this.props.responseBody && this.props.responseBody.message) || ''}
            errors={(this.props.responseBody && this.props.responseBody.errors) || []}
            onClose={this.hideMessage}
            errorFormatter={this.errorFormatter}
          />

          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <BrandFilter />
          <InfoMessage message={INFO_MESSAGE_TEXT} />

          <CsvUploader onFileUpload={this.onFileUpload} />

          {this.renderTemplateLinks()}

          <FirstDeliveryWeekPicker
            selectedFirstDeliveryWeek={this.state.selectedFirstDeliveryWeek}
            onChange={this.setFirstDeliveryWeek}
          />

          <button
            className='delivery-options-update__save-button button-primary'
            disabled={this.isBtnDisabled()}
            onClick={this.openPreviewModal}
          >
            Save
          </button>
        </div>
      )
    }

    return null
  }
}

DeliveryOptionsUpdate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  responseBody: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.deliveryOptionsUpdate.csvContent,
    isLoading: state.deliveryOptionsUpdate.isLoading,
    responseBody: state.deliveryOptionsUpdate.responseBody,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedDeliveryOptionsUpdate = connect(mapStateToProps)(DeliveryOptionsUpdate)

export default connectedDeliveryOptionsUpdate
