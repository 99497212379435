import Papa from 'papaparse'
import { camelCase } from 'lodash'

export const parseCsv = csvData => {
  const { data, errors } = Papa.parse(csvData, {
    header: true,
    delimiter: ',',
    skipEmptyLines: true,
    transformHeader: header => camelCase(header),
  })

  if (errors.length > 0) {
    throw errors.map(error => ({ message: error.message }))
  }

  return data
}

// Formats a CSV to the format encountered in
// src/components/deliveryDateExceptions/deliveryDateExceptions.js
export const formatExceptionsCsv = (country, regularDeliveryDate, parsedCsv) => {
  let exceptions = []

  parsedCsv.forEach(row => {
    const regularShipmentDate = new Date(regularDeliveryDate)
    regularShipmentDate.setHours(regularShipmentDate.getHours() - row.transitTime)

    const exceptionalShipmentDate = new Date(row.exceptionalDeliveryDate)
    exceptionalShipmentDate.setHours(exceptionalShipmentDate.getHours() - row.transitTime)

    const aDayInMicroseconds = 86400000
    const cutoffDate = new Date(row.cutoffDate)
    const cutoffDays = Math.round((new Date(regularDeliveryDate) - cutoffDate) / aDayInMicroseconds)

    const priceTierIdentifier = row.priceTierIdentifier || null

    let exception = {
      country: country.toUpperCase(),
      region: row.region,
      suburb: row.suburb || null,
      transitTime: parseInt(row.transitTime),
      productionSite: row.productionSite,
      regularCutoffDate: row.cutoffDate,
      regularShipmentDate: regularShipmentDate.toISOString().substr(0, 10),
      regularDeliveryDate: regularDeliveryDate,
      regularSlot: {
        shipperName: row.shipper,
        from: row.from,
        to: row.to,
        priceTierIdentifier: priceTierIdentifier,
      },
      exceptionalCutoffDate: row.exceptionalCutoffDate,
      exceptionalShipmentDate: exceptionalShipmentDate.toISOString().substr(0, 10),
      exceptionalDeliveryDate: row.exceptionalDeliveryDate,
      exceptionalSlot: {
        shipperName: row.exceptionalShipper,
        from: row.exceptionalFrom,
        to: row.exceptionalTo,
      },
      exceptionType: 'holiday',
      cutoffDays: cutoffDays,
    }

    exceptions.push(exception)
  })

  return exceptions
}

export const validateCsv = parsedCsv => {
  let index
  let errors = []
  try {
    parsedCsv.forEach((row, i) => {
      index = i + 1
      isDateISO(row.cutoffDate)
      isDateISO(row.exceptionalCutoffDate)
      isDateISO(row.exceptionalDeliveryDate)
      isTimeFourDigits(row.from)
      isTimeFourDigits(row.to)
      isTimeFourDigits(row.exceptionalFrom)
      isTimeFourDigits(row.exceptionalTo)
    })
  } catch (e) {
    errors.push(e + ' on line ' + index)
  }

  if (errors.length > 0) {
    throw { message: 'Validation of CSV failed', errors: errors }
  }
}

const isDateISO = date => {
  let new_date = new Date(date)
  if (isNaN(new_date) || !date.match(/\d{4}-\d{2}-\d{2}/)) {
    throw 'Invalid Date'
  }
}

const isTimeFourDigits = time => {
  if (!time.match(/(?<!\d)\d{1,2}:\d{2}/)) {
    throw 'Invalid Time'
  }
}
