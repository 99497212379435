import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'

import { getContinentByCountry } from 'helpers/countryHelper'
import { pickCSVTemplate } from 'config/csvTemplates'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import BrandFilter from 'components/shared/brandFilter/brandFilter'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'
import FirstDeliveryWeekPicker from '../deliveryOptionsUpdate/firstDeliveryWeekPicker/firstDeliveryWeekPicker'
import FlashMessage from '../flashMessage/flashMessage'
import { csvContainsLowCutoffDays } from 'helpers/csvLowCutoffDaysChecker'

import Toggle from 'react-toggle'
import 'react-toggle/style.css'

import './dynamicOptionsUpdate.css'
import { updateDynamicOptionsCsvUpload } from 'actions/dynamicOptions'

import { showModal } from 'actions/modal'
import { DYNAMIC_OPTIONS_UPDATE_PREVIEW_MODAL } from 'components/modal/modalTypes'

import { DeliveryOptionErrorFormatter } from 'helpers/deliveryOptionErrorFormatter'

const INFO_MESSAGE_TEXT =
  'Updating either Transit Time or Production Site would move the orders that were  ' +
  'associated with the previous values. Old delivery options will no longer be available.'

export class DynamicOptionsUpdate extends React.Component {
  errorFormatter = new DeliveryOptionErrorFormatter()

  constructor(props) {
    super(props)

    this.state = {
      selectedFirstDeliveryWeek: null,
      createNewIfNotExist: false,
      showMessage: false,
      showCutoffDaysWarning: false,
    }
  }

  componentDidMount() {
    this.setState({
      showMessage: false,
      createNewIfNotExist: false,
      showCutoffDaysWarning: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.csvContent !== prevProps.csvContent) {
      this.setState({ selectedFirstDeliveryWeek: null })
    }

    if (this.props.responseBody !== prevProps.responseBody) {
      this.setState({ showMessage: !!this.props.responseBody })
    }
  }

  hideMessage = () => {
    this.setState({ showMessage: false })
  }

  setFirstDeliveryWeek = selectedFirstDeliveryWeek => {
    this.setState({ selectedFirstDeliveryWeek })
  }

  setCreateNewIfNotExist = () => {
    this.setState(prevState => {
      return { createNewIfNotExist: !prevState.createNewIfNotExist }
    })
  }

  onFileUpload = csvData => {
    this.setState({ showCutoffDaysWarning: csvContainsLowCutoffDays(csvData, 'update') })

    this.props.dispatch(updateDynamicOptionsCsvUpload(csvData))
  }

  openPreviewModal = () => {
    this.props.dispatch(
      showModal(DYNAMIC_OPTIONS_UPDATE_PREVIEW_MODAL, {
        firstDeliveryWeek: this.state.selectedFirstDeliveryWeek,
        createNewIfNotExist: this.state.createNewIfNotExist,
        showCutoffDaysWarning: this.state.showCutoffDaysWarning,
      }),
    )
  }

  isBtnDisabled() {
    const { country, brand, csvContent } = this.props
    return !country || !brand || !csvContent || !this.state.selectedFirstDeliveryWeek
  }

  getTemplate = () => {
    const { country } = this.props
    if (!country) return null

    const continent = getContinentByCountry(country)

    return pickCSVTemplate('update-ddn-options', continent)
  }

  renderTemplateLinks = () => {
    const { country } = this.props
    if (country) {
      return (
        <div className='dynamic-options-update__template-links'>
          <ZiplogLink
            href={this.getTemplate()}
            className='dynamic-options-update__ziplog-link'
            text='CSV Template'
            openInNewTab
          />
        </div>
      )
    } else {
      return (
        <Alert severity='info' sx={{ width: 'max-content', marginTop: '10px' }}>
          Please select a country to view template
        </Alert>
      )
    }
  }

  render() {
    if (this.props.isLoading) {
      return <div className='dynamic-options-update__loader'>Loading...</div>
    } else if (this.props.canManage) {
      return (
        <div className='dynamic-options-update'>
          <FlashMessage
            showMessage={this.state.showMessage}
            message={(this.props.responseBody && this.props.responseBody.message) || ''}
            errors={(this.props.responseBody && this.props.responseBody.errors) || []}
            onClose={this.hideMessage}
            errorFormatter={this.errorFormatter}
          />

          <CountryFilter countries={this.props.countriesAllowedToManage} />
          <BrandFilter />
          <InfoMessage message={INFO_MESSAGE_TEXT} />

          <CsvUploader onFileUpload={this.onFileUpload} />

          {this.renderTemplateLinks()}

          <FirstDeliveryWeekPicker
            selectedFirstDeliveryWeek={this.state.selectedFirstDeliveryWeek}
            onChange={this.setFirstDeliveryWeek}
          />

          <div className='create-new-do'>
            <label className='create-new-toggle-label' htmlFor='cheese-status'>
              Create new delivery option if it does not exist
            </label>

            <Toggle
              className='create-new-toggle'
              defaultChecked={this.state.createNewIfNotExist}
              onChange={this.setCreateNewIfNotExist}
            />
          </div>

          <button
            className='dynamic-options-update__save-button button-primary'
            disabled={this.isBtnDisabled()}
            onClick={this.openPreviewModal}
          >
            Save
          </button>
        </div>
      )
    }

    return null
  }
}

DynamicOptionsUpdate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brand: PropTypes.string,
  csvContent: PropTypes.string,
  isLoading: PropTypes.bool,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  responseBody: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brand: state.globalFilters.brand,
    csvContent: state.dynamicOptionsUpdate.csvContent,
    isLoading: state.dynamicOptionsUpdate.isLoading,
    responseBody: state.dynamicOptionsUpdate.responseBody,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
  }
}

const connectedDynamicOptionsUpdate = connect(mapStateToProps)(DynamicOptionsUpdate)

export default connectedDynamicOptionsUpdate
