// prettier-ignore
const addDeliveryOptionsTemplates = [
  {
    continent: 'europe',
    template: {
      production: {
        zip: 'https://docs.google.com/spreadsheets/d/11l7R8_TvqxQbXMXoypGefosFgyFi51F-3cLruYVH7Mc',
        region: 'https://docs.google.com/spreadsheets/d/1Hrra1YilCHXKClf1b0o1icN4l_ye9LH6HMIHUOs0EFw',
      },
      staging: {
        zip: 'https://docs.google.com/spreadsheets/d/1TJrfnBfTVDlUTF62xI5UOCnXnd1EC-b9WjMAUrsd6QU',
        region: 'https://docs.google.com/spreadsheets/d/1gHTaxB5JHxqtuH_44VybUS2GRIjsZTjTbP35mc4ag3s',
      },
    },
  },
  {
    continent: 'north america',
    template: {
      production: {
        zip: 'https://docs.google.com/spreadsheets/d/16Xipg4I22IoGHSeq02XgSpew_NoN852tAt5yfy0rXJw',
        region: 'https://docs.google.com/spreadsheets/d/1Euw2VWcgxrjGzSYYekbuchNreNGoH5hXm4bH4-RGJhc',
      },
      staging: {
        zip: 'https://docs.google.com/spreadsheets/d/1GlkA52pWhFPbd93z7OdcqJvcH7QAuffB54-sULe6i20',
        region: 'https://docs.google.com/spreadsheets/d/1QIEsji7BN3vmkOlBQOhYFm3wjoedfP5xs53EzrceLU4',
      },
    },
  },
  {
    continent: 'oceania',
    template: {
      production: {
        zip: 'https://docs.google.com/spreadsheets/d/1DbD0_paUWUfqJljUA6l98Jum11VK7MzhRIWA4NTVYQ0',
        region: 'https://docs.google.com/spreadsheets/d/1nBRLcc8D_O-p2yGNVTLKUH76JvGo4ZD0b-yw595tbq4',
      },
      staging: {
        zip: 'https://docs.google.com/spreadsheets/d/1-PEwaf7tTs0yLhH7nd1U72DVC6Wx-5b1ze2CrC0y5x8',
        region: 'https://docs.google.com/spreadsheets/d/1ksxxV4GnovMP_QJx77YVWCWnbt0w1YyQ7hTa0k77oB4',
      },
    },
  },
]

// prettier-ignore
const updateDeliveryOptionsTemplates = [
  {
    continent: 'europe',
    template: {
      production: {
        zip: 'https://docs.google.com/spreadsheets/d/1OikrtwlRanD-jsmzHk-fLOlL7-eeY2fL9RKILd9B-DE',
        region: 'https://docs.google.com/spreadsheets/d/1s7coKObi_zsNXJMgB3NxHpCLo0vtUP8-l6_9t4vfs70',
      },
      staging: {
        zip: 'https://docs.google.com/spreadsheets/d/1NcR0zs2kJ4dsF2oYARz5bjG2K2ONGHMeRLfGgTqBSn4',
        region: 'https://docs.google.com/spreadsheets/d/1QIr_hg_GT0x5dR9rzfZ-Y0Kw6EM7mC1fAzBv3sHTSDQ',
      },
    },
  },
  {
    continent: 'north america',
    template: {
      production: {
        zip: 'https://docs.google.com/spreadsheets/d/1zubwg8P3RCaz6rY7tCY_puc7Ddwl3WGC2egq_TqWtNo',
        region: 'https://docs.google.com/spreadsheets/d/11HhpWy187CVlnNPTCfRtR6Cbdmx2TBoTSV5vTCaO_lU',
      },
      staging: {
        zip: 'https://docs.google.com/spreadsheets/d/10tpxyqLBUObrsvIlWsOIh0-Ov8UdCRYC_zT0UCLeeEQ',
        region: 'https://docs.google.com/spreadsheets/d/1zyXCNHWlgt0MEFloSZpPW1SZ_C-o5DEkHn05p7TKGVk',
      },
    },
  },
  {
    continent: 'oceania',
    template: {
      production: {
        zip: 'https://docs.google.com/spreadsheets/d/1ucXvdlWp0oNBy6rfodbR5hoZ3JliJHBWh-umgdxY9dw',
        region: 'https://docs.google.com/spreadsheets/d/1pjewIjuTqzocQgBSBzmmuM8PokRQ9XwcyBirUNTs38w',
      },
      staging: {
        zip: 'https://docs.google.com/spreadsheets/d/1pPcXEe3G_5MFv4IldAWiUnMw7MZ_G6qJjdcwxw7cPgY',
        region: 'https://docs.google.com/spreadsheets/d/1HsdEHUqcz9VR1jHt5bgzPOolIfHKiswJSEfmTgU-MDU',
      },
    },
  },
]

// prettier-ignore
const updateDDNOptionsTemplates = [
  {
    continent: 'europe',
    template: {
      production: 'https://docs.google.com/spreadsheets/d/1dw9PGZM8RN2vwJDzJFrKRablhT79rmUPA6L1XurFmr8',
      staging: 'https://docs.google.com/spreadsheets/d/173N0T6qAzReF8mZDR-gmsLisTn2VHZ_8k5w7CTVCXcY',
    },
  },
  {
    continent: 'north america',
    template: {
      production: 'https://docs.google.com/spreadsheets/d/1EX5KYsKo2uc9cg0BU-JxVmfatlQlp5oCzO_y6Asb2DI',
      staging: 'https://docs.google.com/spreadsheets/d/1LMdSnckC1XnP9PcLO4BDZMa5XVEd97TEa6B4_nyf_JI',
    },
  },
  {
    continent: 'oceania',
    template: {
      production: 'https://docs.google.com/spreadsheets/d/1KQL__2w3KUVHHwQHksdD0NvYpU5Q6ubEsfetHLoC2qU',
      staging: 'https://docs.google.com/spreadsheets/d/1Nrp4uVFwpvfYw83gvsm9SAF8yCruVT1XZ1C4lA0xGyk',
    },
  },
]

// prettier-ignore
const addExceptionsTemplates = [
  {
    continent: 'europe',
    template: {
      production: 'https://docs.google.com/spreadsheets/d/1CxQ1IvKnjpdTzqLAq591tAIQB9C9E5aE_2k2XU7O2qc/edit?usp=sharing',
      staging: 'https://docs.google.com/spreadsheets/d/1h06WfSO1QsmDDUe9n8ChnjIlL6rqE9rQSn2LOOtNnPc/edit?usp=sharing',
    },
  },
  {
    continent: 'north america',
    template: {
      production: 'https://docs.google.com/spreadsheets/d/1CxQ1IvKnjpdTzqLAq591tAIQB9C9E5aE_2k2XU7O2qc/edit?usp=sharing',
      staging: 'https://docs.google.com/spreadsheets/d/1h06WfSO1QsmDDUe9n8ChnjIlL6rqE9rQSn2LOOtNnPc/edit?usp=sharing',
    },
  },
  {
    continent: 'oceania',
    template: {
      production: 'https://docs.google.com/spreadsheets/d/1gEuFkcL8dgCosMtkcy7vZHzaz9D9d-0_0VvFD3AvIjk/edit?usp=sharing',
      staging: 'https://docs.google.com/spreadsheets/d/1F6OOtUHKowYFtgZDrIaBnbs_HeO1FemDsx9OateKW_o/edit?usp=sharing',
    },
  },
]

const csvTemplates = [
  {
    type: 'add-delivery-options',
    templates: addDeliveryOptionsTemplates,
  },
  {
    type: 'update-delivery-options',
    templates: updateDeliveryOptionsTemplates,
  },
  {
    type: 'update-ddn-options',
    templates: updateDDNOptionsTemplates,
  },
  {
    type: 'add-exceptions',
    templates: addExceptionsTemplates,
  },
]

// Picks templates based on csv type, continent, template type and current ENV
//
// Available csv types: add-delivery-options, update-delivery-options, update-ddn-options
// Available template types: zip, region
export const pickCSVTemplate = (csvType, continent, templateType = null) => {
  const availableTypes = csvTemplates.map(t => t.type)

  if (!availableTypes.includes(csvType)) {
    console.error(
      `${csvType} is not a correct csv template type, Avalable types: ${availableTypes}`,
    )

    return
  }

  const env = process.env.REACT_APP_ENV === 'production' ? 'production' : 'staging'

  const templatesByCsvType = csvTemplates.find(t => t.type === csvType).templates
  const templateByContinent = templatesByCsvType.find(t => t.continent === continent).template

  return templateType ? templateByContinent[env][templateType] : templateByContinent[env]
}
