import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'

import { setGlobalFilter } from 'actions'
import { exceptionsCreateByCsvUpload } from 'actions/deliveryDateExceptions'
import { showModal } from 'actions/modal'

import { parseCsv, validateCsv } from 'helpers/exceptionsByCsv'
import { getContinentByCountry } from 'helpers/countryHelper'
import FlashMessage from '../flashMessage/flashMessage'

import InfoMessage from 'components/shared/infoMessage/infoMessage'
import CsvUploader from 'components/shared/csvUploader/csvUploader'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import ZiplogDatepicker from 'components/shared/ziplogDatepicker/ziplogDatepicker'
import ZiplogLink from 'components/shared/ziplogLink/ziplogLink'
import { MultipleBrandsFilter } from 'components/shared/multipleBrandFilter/multipleBrandsFilter'
import { EXCEPTION_CREATE_BY_CSV } from 'components/modal/modalTypes'
import { pickCSVTemplate } from 'config/csvTemplates'

import './deliveryDateExceptions.css'

export class DeliveryDateExceptionsByCsvErrorFormatter {
  format = errors => {
    return errors.map(error => ({ message: error }))
  }
}

export const DeliveryDateExceptionsCsvs = props => {
  const { dispatch, isLoading, canManage } = props
  const [showMessage, setShowMessage] = React.useState(false)
  const [validationErrors, setValidationErrors] = React.useState({})

  const openPreviewModal = () => {
    dispatch(showModal(EXCEPTION_CREATE_BY_CSV))
  }

  const updateSelectedDate = date => {
    dispatch(setGlobalFilter('regularDeliveryDate', date))
  }

  const hideMessage = () => {
    setShowMessage(false)
  }

  const isSaveBtnDisabled = () => {
    const { brands, country, regularDeliveryDate, parsedCsvContent } = props
    return (
      !(brands.length > 0) ||
      !country ||
      !regularDeliveryDate ||
      !(new Date(regularDeliveryDate) > new Date()) ||
      !parsedCsvContent
    )
  }

  const onFileUpload = csvData => {
    if (csvData === '') return

    let parsedCsv = []
    try {
      parsedCsv = parseCsv(csvData)
      validateCsv(parsedCsv)
    } catch (e) {
      setShowMessage(true)
      setValidationErrors(e)
      return
    }

    setShowMessage(false)
    setValidationErrors([])
    dispatch(exceptionsCreateByCsvUpload(parsedCsv))
  }

  const getTemplate = () => {
    const { country } = props
    if (!country) return null

    const continent = getContinentByCountry(country)

    return pickCSVTemplate('add-exceptions', continent)
  }

  const renderTemplateLinks = () => {
    const { country } = props
    if (country) {
      return (
        <div>
          <ZiplogLink href={getTemplate()} text='CSV Template' openInNewTab />
        </div>
      )
    } else {
      return (
        <Alert severity='info' sx={{ width: 'max-content', marginTop: '10px' }}>
          Please select a country to view template
        </Alert>
      )
    }
  }

  if (isLoading) {
    return (
      <Fragment>
        <div className='delivery-options-new__loader'>Loading...</div>
      </Fragment>
    )
  } else if (canManage) {
    return (
      <Fragment>
        <div>
          <FlashMessage
            showMessage={showMessage}
            message={validationErrors?.message || ''}
            errors={validationErrors?.errors || []}
            onClose={hideMessage}
            errorFormatter={new DeliveryDateExceptionsByCsvErrorFormatter()}
          />

          <CountryFilter countries={props.countriesAllowedToManage} />
          <MultipleBrandsFilter dispatch={dispatch} />
          <ZiplogDatepicker
            defaultDate={props.regularDeliveryDate}
            onDateSelect={updateSelectedDate}
            labelText='Regular Delivery Date'
          />

          <InfoMessage
            message={'Double check the CSV contents before uploading'}
            className='warning-message'
          />
          <CsvUploader onFileUpload={onFileUpload} />

          {renderTemplateLinks()}

          <button
            className='delivery-date-exceptions-csvs__save-button button-primary'
            disabled={isSaveBtnDisabled()}
            onClick={openPreviewModal}
          >
            Save
          </button>
        </div>
      </Fragment>
    )
  }
}

DeliveryDateExceptionsCsvs.propTypes = {
  brands: PropTypes.array,
  canManage: PropTypes.bool.isRequired,
  countriesAllowedToManage: PropTypes.array.isRequired,
  country: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  parsedCsvContent: PropTypes.array,
  regularDeliveryDate: PropTypes.string,
  validationErrors: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    brands: state.globalFilters.multipleBrands,
    canManage: state.currentUser.canManage,
    countriesAllowedToManage: state.currentUser.countriesAllowedToManage,
    country: state.globalFilters.country,
    isLoading: state.deliveryDateExceptions.isLoading,
    parsedCsvContent: state.deliveryDateExceptions.parsedCsvContent,
    regularDeliveryDate: state.globalFilters.regularDeliveryDate,
    validationErrors: state.deliveryDateExceptions.validationErrors,
  }
}

const connectedDeliveryDateExceptionsCsvs = connect(mapStateToProps)(DeliveryDateExceptionsCsvs)

export default connectedDeliveryDateExceptionsCsvs
