import React from 'react'
import PropTypes from 'prop-types'

import ZiplogTable from 'components/shared/ziplogTable/ziplogTable'

import './flashMessage.css'

export default function FlashMessage(props) {
  if (props.showMessage) {
    const reformattedErrors = props.errorFormatter.format(props.errors)
    return (
      <div className={`flash-message ${reformattedErrors.length > 0 ? 'error' : 'success'}`}>
        <button className='flash-message__close' onClick={() => props.onClose()}>
          ✕
        </button>
        <p className='flash-message__message'>{props.message}</p>
        {reformattedErrors.length > 0 && (
          <div className='flash-message__table_wrapper'>
            <ZiplogTable
              tableName='flash-message__table'
              headers={Object.keys(reformattedErrors[0])}
              data={reformattedErrors}
            />
          </div>
        )}
      </div>
    )
  }

  return ''
}

FlashMessage.propTypes = {
  showMessage: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  errors: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  errorFormatter: PropTypes.object.isRequired,
}
