import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BaseModal from 'components/modal/baseModal'
import { hideModal } from 'actions/modal'
import { createExceptionsByCsv } from 'actions/deliveryDateExceptions'
import { formatExceptionsCsv } from 'helpers/exceptionsByCsv'

import './previewModal.css'

export const PreviewModal = props => {
  const closeModal = () => {
    props.dispatch(hideModal())
  }

  const submitExceptionCreateByCsv = () => {
    let data = formatExceptionsCsv(props.country, props.regularDeliveryDate, props.parsedCsvContent)
    props.dispatch(
      createExceptionsByCsv(props.brands, props.country, props.regularDeliveryDate, data),
    )

    closeModal()
  }

  return (
    <BaseModal onClose={closeModal}>
      <p className='exception-create-by-csvs-preview-modal__text'>
        Are you sure you want create these exceptions?
      </p>
      <button
        className='exception-create-by-csvs-preview-modal__cancel-button'
        onClick={closeModal}
      >
        Cancel
      </button>
      <button
        className='button-primary exception-create-by-csvs-preview-modal__submit-button'
        onClick={submitExceptionCreateByCsv}
      >
        {`Yes, I'm sure`}
      </button>
    </BaseModal>
  )
}

PreviewModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brands: PropTypes.array,
  country: PropTypes.string,
  parsedCsvContent: PropTypes.string,
  regularDeliveryDate: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    brands: state.globalFilters.multipleBrands,
    country: state.globalFilters.country,
    regularDeliveryDate: state.globalFilters.regularDeliveryDate,
    parsedCsvContent: state.deliveryDateExceptions.parsedCsvContent,
  }
}

const connectedPreviewModal = connect(mapStateToProps)(PreviewModal)

export default connectedPreviewModal
