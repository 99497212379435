import { weekdayString } from 'helpers/dateHelper'
import { truncate } from 'lodash'

export class DeliveryOptionErrorFormatter {
  showNewDeliveryOption = false

  constructor(showNewDeliveryOption) {
    this.showNewDeliveryOption = showNewDeliveryOption
  }

  deliveryOptionString = deliveryOption => {
    let optionText =
      `${weekdayString(deliveryOption.wday)}, ` +
      `${deliveryOption.shipper_name} ` +
      `${deliveryOption.from}-${deliveryOption.to}`

    if (deliveryOption.price_tier_identifier) {
      optionText += ` (${deliveryOption.price_tier_identifier})`
    }
    return optionText
  }

  format = errors => {
    return errors.map(error => {
      let reformattedError = {}

      if (Array.isArray(error.area)) {
        reformattedError.area = truncate(error.area.join(', '), {
          length: 30,
          separator: ' ',
        })
      } else {
        reformattedError.area = error.area
      }

      reformattedError.deliveryOption = this.deliveryOptionString(error.delivery_option)
      if (this.showNewDeliveryOption) {
        reformattedError.newDeliveryOption = this.deliveryOptionString(error.new_delivery_option)
      }
      reformattedError.message = error.message

      return reformattedError
    })
  }
}
